exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-[type]-[id]-jsx": () => import("./../../../src/pages/preview/[type]/[id].jsx" /* webpackChunkName: "component---src-pages-preview-[type]-[id]-jsx" */),
  "component---src-templates-event-template-jsx": () => import("./../../../src/templates/eventTemplate.jsx" /* webpackChunkName: "component---src-templates-event-template-jsx" */),
  "component---src-templates-faq-template-jsx": () => import("./../../../src/templates/faqTemplate.jsx" /* webpackChunkName: "component---src-templates-faq-template-jsx" */),
  "component---src-templates-page-template-jsx": () => import("./../../../src/templates/pageTemplate.jsx" /* webpackChunkName: "component---src-templates-page-template-jsx" */),
  "component---src-templates-prefecture-template-jsx": () => import("./../../../src/templates/prefectureTemplate.jsx" /* webpackChunkName: "component---src-templates-prefecture-template-jsx" */),
  "component---src-templates-topic-template-jsx": () => import("./../../../src/templates/topicTemplate.jsx" /* webpackChunkName: "component---src-templates-topic-template-jsx" */),
  "component---src-templates-video-template-jsx": () => import("./../../../src/templates/videoTemplate.jsx" /* webpackChunkName: "component---src-templates-video-template-jsx" */)
}

