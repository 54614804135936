import * as React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql, withPrefix } from "gatsby"
import { useLocalization } from "../hooks/use-localization"

const Seo = ({ location, pageContext }) => {
  const { locale, config } = useLocalization()
  const data = useStaticQuery(graphql`
    query LocalizationSEOQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)
  const defaultSiteUrl = data.site.siteMetadata.siteUrl
  const { pathname } = location

  const translations = pageContext.translations.map(translation => {
    translation.hrefLang = config.find(
      c => c.code === translation.locale
    )?.hrefLang
    return translation
  })

  const pageHrefLang = config.find(c => c.code === pageContext.locale)?.hrefLang

  return (
    <Helmet>
      <html lang={pageHrefLang.hrefLang} />
      <link rel="alternate" hrefLang="x-default" href={defaultSiteUrl} />
      <link
        rel="alternate"
        hrefLang={pageHrefLang.hrefLang}
        href={`${defaultSiteUrl}${
          pathname === withPrefix(`/`) ? `` : pathname
        }`}
      />
      {translations.map(l => {
        if (l.locale === locale) return null

        return (
          <link
            key={l.locale}
            rel="alternate"
            hrefLang={l.hrefLang}
            href={l.slug}
          />
        )
      })}
      <meta property="og:locale" content={pageHrefLang.replace(`-`, `_`)} />
      {translations.map(l => {
        if (l.locale === locale) return null
        return (
          <meta
            key={l.locale}
            property="og:locale:alternate"
            content={l.hrefLang.replace(`-`, `_`)}
          />
        )
      })}
    </Helmet>
  )
}

export { Seo }
